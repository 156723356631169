<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
        <CHeaderNavLink >
          <CIcon name="cil-bell" />
          <CBadge :color="notificacion.notfSinLeer>0?'danger':'success'" class="ml-auto">{{notificacion.notfSinLeer}}</CBadge>
        </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Mis Notificaciones</strong>
    </CDropdownHeader>

    <CDropdownItem v-show="notificacion.notificaciones.length==0">
        <div class="text-center" style="width:100%">No tiene notificaciones para mostrar</div>
        <!--<CBadge color="info" class="ml-auto">visto</CBadge>-->
    </CDropdownItem>
    

    <CDropdownItem v-for="notif in notificacion.notificaciones" :key="notif.idNotificacion" :style="(!notif.estadoLectura)?'background-color:#ced2d8':''">
        <CIcon name="cil-comment-square" /> {{notif.mensajeNotificacion}} <!--<CBadge color="light" class="ml-auto">{{notif.fechaNotificacion}}</CBadge>-->
    </CDropdownItem>
    
    <CDropdownDivider/>

    <CDropdownItem @click="leerNotificaciones" v-show="notificacion.notfSinLeer>0" style="padding: 0.2rem 0.25rem; font-size: 11px;">
        <div class="text-center" style="width:100%">Marcar como leídas</div>
        <!--<CBadge color="info" class="ml-auto">visto</CBadge>-->
    </CDropdownItem>

  </CDropdown>
</template>

<script>

    import cons from '@/const'
    import axios from "axios"
    export default {
    name: 'Notificaciones',
    data () {
        return { 
            urlBase: cons.port+"://" + cons.ipServer,
            tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,

            isLoadingNotificaciones: false,

            notificacion: {
                "notfSinLeer": 0,
                "notificaciones": []
            }
        }
    },
    beforeMount() {
        /*
        if(cons.intervalNotificacionID == null){
            this.getNotificaciones()
            var idInt = setInterval(() => {
                if(this.$cookie.get('userLogginToken') === null || this.$cookie.get('userLogginToken') === ""){
                    this.$router.push("/pages/login");
                } else{
                    this.getNotificaciones();
                }
                
            }, (60000 * 5));

            cons.intervalNotificacionID = idInt
        }*/
        
    },
    methods: {
        getNotificaciones: function(){
            this.isLoadingNotificaciones = true
            
            var url = this.urlBase + "/get_notificaciones"
                    
            axios({
                method: "GET", 
                "url": url,
                "headers": {
                    'Authorization': `${this.tokenLogin}`
                }
            }).then(result => {
                this.notificacion.notfSinLeer = result.data.notfSinLeer
                this.notificacion.notificaciones = result.data.notificaciones
            }, error => {
                this.mensajeNotificacion = "Ups, Tuvimos un problema al obtener los Documentos, si el problema persiste contacte con nuestro soporte, Gracias."
            })
            .finally(() => {
                this.isLoadingNotificaciones = false
            });
        },
        leerNotificaciones: function(){
            
            var notifSinLeerIds = [] 
            for(var notif in this.notificacion.notificaciones){
                if(!this.notificacion.notificaciones[notif].estadoLectura){
                    notifSinLeerIds.push(this.notificacion.notificaciones[notif].idNotificacion)
                }
            }
            
            var url = this.urlBase + "/leer_notificaciones/"+notifSinLeerIds

            axios({
                method: "GET", 
                "url": url,
                "headers": {
                    'Authorization': `${this.tokenLogin}`
                }
            }).then(result => {
                this.mensajeNotificacion = result.data
                this.getNotificaciones()
            }, error => {
                this.mensajeNotificacion = "Ups, Tuvimos un problema al obtener los Documentos, si el problema persiste contacte con nuestro soporte, Gracias."
            })
            .finally(() => {
                //this.isLoadingNotificaciones = false
            });
        }

    }
    }
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
  .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
        width: 1.3rem;
        height: 1.3rem;
  }
</style>