import { render, staticRenderFns } from "./TheSidebar.vue?vue&type=template&id=e5887692&scoped=true"
import script from "./TheSidebar.vue?vue&type=script&lang=js"
export * from "./TheSidebar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5887692",
  null
  
)

export default component.exports