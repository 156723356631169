<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://vapp.cl" target="_blank" style="font-size:'14px'">{{appName}}</a>
      <span class="ml-1" style="font-size:'9px'">by Vapp &copy; {{ new Date().getFullYear() }} </span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Versión {{version}}</span>
      <!--<a href="https://vapp.cl">Vapp</a>-->
    </div>
  </CFooter>
</template>

<script>

  import cons from '@/const'
export default {
    name: 'TheFooter',
    data () {
        return {
            appName: cons.appName,
            version: cons.version
        }
    }
}
</script>
