<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
            style="height: 30px; width: auto;"
            src="img/avatars/sin_avatar.png"
            class="c-avatar-img"
            :class="(isLoadingCierreSesion)? 'imgr': ''"
          /><span
              class="c-avatar-status" :class="(isLoadingCierreSesion)? 'bg-warning': 'bg-success'"
            ></span>
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader v-if="tipoUsuario == 'trabajador'">
      <CRow>
        <CCol sm="12">
          <center>
          <div class="c-avatar">
            <img
              src="img/avatars/sin_avatar.png"
              class="c-avatar-img "
            />
            <span
              class="c-avatar-status" :class="(isLoadingCierreSesion)? 'bg-warning': 'bg-success'"
            ></span>
          </div>
        </center>
      </CCol>
      </CRow>
      <CRow>
        <CCol sm="12"><center>{{formatoNombre(nombreTrab,aellidoTrab)}}</center></CCol>
      </CRow>
      <CRow>
        <CCol sm="12"><center>{{correoTrab}}</center></CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <center>
            <router-link to="/perfil">Ver Perfil </router-link>
          </center>
        </CCol>
      </CRow>
    </CDropdownHeader>
    
    <CDropdownHeader v-else tag="div" class="text-center" >
      <!--<strong>Mi Cuenta</strong>-->
        <CRow>
            <CCol sm="12">
            <center>
                <div class="c-avatar">
                    <img src="img/avatars/sin_avatar.png" class="c-avatar-img" />
                    <span class="c-avatar-status" :class="(isLoadingCierreSesion)? 'bg-warning': 'bg-success'"></span>
                </div>
            </center>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12"><center>{{nombreUsuario}}</center></CCol>
      </CRow>
      <CRow>
        <CCol sm="12"><center>{{tipoUsuario?'Admin':'Normal'}}</center></CCol>
      </CRow>
      
      <!--<CRow>
        <CCol sm="12">
          <center>
            <router-link to="/">Ver Tutoriales </router-link>
          </center>
        </CCol>
      </CRow>
      
      <CRow>
        <CCol sm="12">
          <center>
            <router-link to="/">Ver otras coasas </router-link>
          </center>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <center>
            <router-link to="/">Ver más coasas </router-link>
          </center>
        </CCol>
      </CRow>-->    
    </CDropdownHeader>

    <!--<CDropdownItem>
      <CIcon name="cil-bell"/> Updates
      <CBadge color="info" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-envelope-open" /> Messages
      <CBadge color="success" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-task" /> Tasks
      <CBadge color="danger" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-comment-square" /> Comments
      <CBadge color="warning" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownHeader
      tag="div"
      class="text-center"
      color="light"
    >
      <strong>Settings</strong>
    </CDropdownHeader>
    <CDropdownItem>
      <CIcon name="cil-user" /> Profile
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-settings" /> Settings
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-dollar" /> Payments
      <CBadge color="secondary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-file" /> Projects
      <CBadge color="primary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownDivider/>
    <CDropdownItem>
      <CIcon name="cil-shield-alt" /> Lock Account
    </CDropdownItem>-->
    
    <!--<CDropdownItem >
        <span><CIcon name="cil-video" /> Ver Tutoriales</span>
    </CDropdownItem>
    <CDropdownItem >
        <span><CIcon name="cil-clipboard" /> Ver Manual</span>
    </CDropdownItem>-->
    <CDropdownDivider style="background-color: #29235C;"/>
    <CDropdownItem @click="cerrarSesion" :disabled="isLoadingCierreSesion">
        <span v-show="isLoadingCierreSesion"><CIcon name="cil-lock-locked" class="imgr" />Cerrando Sesión</span>
      <span v-show="!isLoadingCierreSesion"><CIcon name="cil-lock-locked" /> Cerrar Sesión</span>
    </CDropdownItem>
  </CDropdown>
</template>

<script>

  import cons from '@/const'
  import axios from "axios"
  
  import CargandoInfo from '@/views/utils/CargandoInfo'

  export default {
    name: 'TheHeaderDropdownAccnt',
    components: {
      CargandoInfo
    },
    data () {
      return { 
        tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
        tipoUsuario: JSON.parse(this.$cookie.get('userLogginToken')).tipoUsuario,
        nombreTrab: JSON.parse(this.$cookie.get('userLogginToken')).nombreTrabajador,
        aellidoTrab: JSON.parse(this.$cookie.get('userLogginToken')).apellidoTrabajador,
        correoTrab: JSON.parse(this.$cookie.get('userLogginToken')).mailTrabajador,
        itemsCount: 42,

        nombreUsuario: JSON.parse(this.$cookie.get('userLogginToken')).nombreUsuario,

        isLoadingCierreSesion: false,

      }
    },
    mounted(){
        //console.log(JSON.parse(this.$cookie.get('userLogginToken')))
    },
    methods: {
      formatoNombre: function(nombre,apellido){
        return nombre.split(" ")[0] + " " +apellido.split(" ")[0]
      },

      cerrarSesion: function(){
        
            /*if(cons.intervalNotificacionID != null){
              clearInterval(cons.intervalNotificacionID)
            }*/
            this.isLoadingCierreSesion = true
            
            var url = cons.port+"://" + cons.ipServer + "/log_off/"
            axios({
                method: "POST", 
                "url": url,
                "data": {
                  "info": {
                    "tipoUsuario": this.tipoUsuario,
                  }
                },
                "headers": {
                    'Authorization': `${this.tokenLogin}`
                }
            }).then(result => {
                
                this.$notification.success(result.data, {  timer: 10, position:"bottomRight"});
                
                this.$cookie.delete('userLogginToken');
                this.$router.push("/pages/login");
                
            }, error => {
                this.$notification.error(error.response.data, {  timer: 10, position:"bottomRight"});
            })
            .finally(() => {
              this.isLoadingCierreSesion = false
                
            });
            
        },
    }
  }
</script>

<style scoped>
    .c-avatar{
        width: 45px;
        height: 46px;
    }
  .c-icon {
    margin-right: 0.3rem;
  }
</style>