<template>
    <div>
        <CDropdown
            inNav
            class="c-header-nav-items"
            placement="bottom-end"
            add-menu-classes="pt-0">
            <template #toggler>
                <CHeaderNavLink>
                    <div class="c-avatar">
                        <!--<CIcon style="width: 50px; height: 30px;" class="c-avatar-img " :content="$options.freeSet.cilColorBorder" />-->
                        <CIcon style="width: 50px; height: 30px;" class="c-avatar-img " :content="$options.freeSet.cilAvTimer" />
                    
                    </div>
                </CHeaderNavLink>
            </template>
            <CDropdownHeader>
                <CRow>
                    <CCol sm="12">
                        <center>
                            
                            <!--<CIcon style="width: 50px; height: 30px;" :content="$options.freeSet.cilAvTimer" />-->
                            <CIcon style="width: 50px; height: 30px;" class="c-avatar-img mb-1" :content="$options.freeSet.cilColorBorder" />
                            <CSelect
                                v-show="mostrarEmpresas"
                                size="sm"
                                :options="empresasTrabajador"
                                @update:value="seleccionarEmpresa"
                                placeholder="Selecciones empresa a registrar"
                            />
                        </center>
                        <!--<CIcon name="cil-shield-alt" /> Lock Account
                            <div class="c-avatar">
                                <img
                                src="img/lunch.png"
                                class="c-avatar-img "
                                />
                                <span
                                class="c-avatar-status bg-success"
                                ></span>
                            </div>-->
                    </CCol>
                </CRow>
            </CDropdownHeader>
            <CDropdownItem 
                v-if="datosRegistro != null && 'ingreso' in datosRegistro" 
                :disabled="!datosRegistro.ingreso.habilitado || datosRegistro.ingreso.registrado" 
                @click="registrarHorario(datosRegistro.ingreso)">                    
                <img
                    class="mr-1"
                    style="width: 25px; height: 25px;"
                    src="img/inicioLab.png"
                    /> {{datosRegistro.ingreso.nombreVisible}}
            </CDropdownItem>
            <CDropdownItem v-if="datosRegistro != null && 'colacionInicio' in datosRegistro" 
                :disabled="!datosRegistro.colacionInicio.habilitado || !datosRegistro.ingreso.registrado || datosRegistro.colacionInicio.registrado" 
                @click="registrarHorario(datosRegistro.colacionInicio)">
                    
                <img
                    class="mr-1"
                    style="width: 30px; height: 30px;"
                    src="img/lunch.png"
                    /> {{datosRegistro.colacionInicio.nombreVisible}}
            </CDropdownItem>
            <CDropdownItem v-if="datosRegistro != null && 'colacionTermino' in datosRegistro" 
                    :disabled="!datosRegistro.colacionTermino.habilitado || !datosRegistro.ingreso.registrado || !datosRegistro.colacionInicio.registrado || datosRegistro.colacionTermino.registrado" 
                    @click="registrarHorario(datosRegistro.colacionTermino)">
                <img
                    class="mr-1"
                    style="width: 30px; height: 30px; backgorund-color:grey;"
                    src="img/lunch2.png"
                    /> {{datosRegistro.colacionTermino.nombreVisible}}
            </CDropdownItem>
            <CDropdownItem v-if="datosRegistro != null && 'salida' in datosRegistro" 
                :disabled="!datosRegistro.salida.habilitado || !datosRegistro.ingreso.registrado || !datosRegistro.colacionInicio.registrado || !datosRegistro.colacionTermino.registrado || datosRegistro.salida.registrado" 
                @click="registrarHorario(datosRegistro.salida)">
                <img
                    class="mr-1"
                    style="width: 30px; height: 30px;"
                    src="img/inicioLab.png"
                    /> {{datosRegistro.salida.nombreVisible}}
            </CDropdownItem>
            <CDropdownItem v-if="datosRegistro != null && 'hExtra' in datosRegistro" 
                :disabled="!datosRegistro.hExtra.habilitado || !datosRegistro.ingreso.registrado || !datosRegistro.colacionInicio.registrado || !datosRegistro.colacionTermino.registrado || !datosRegistro.salida.registrado || datosRegistro.hExtra.registrado"
                @click="registrarHorario(datosRegistro.hExtra)">
                <img
                    class="mr-1"
                    style="width: 30px; height: 30px;"
                    src="img/inicioLab.png"
                    /> {{datosRegistro.hExtra.nombreVisible}}
            </CDropdownItem>
            <!--<CDropdownHeader v-else tag="div" class="text-center" color="light">
            <strong>Mi Cuenta</strong>
            </CDropdownHeader>-->

            <!--<CDropdownItem>
            <CIcon name="cil-bell"/> Updates
            <CBadge color="info" class="mfs-auto">{{ itemsCount }}</CBadge>
            </CDropdownItem>
            <CDropdownItem>
            <CIcon name="cil-envelope-open" /> Messages
            <CBadge color="success" class="mfs-auto">{{ itemsCount }}</CBadge>
            </CDropdownItem>
            <CDropdownItem>
            <CIcon name="cil-task" /> Tasks
            <CBadge color="danger" class="mfs-auto">{{ itemsCount }}</CBadge>
            </CDropdownItem>
            <CDropdownItem>
            <CIcon name="cil-comment-square" /> Comments
            <CBadge color="warning" class="mfs-auto">{{ itemsCount }}</CBadge>
            </CDropdownItem>
            <CDropdownHeader
            tag="div"
            class="text-center"
            color="light"
            >
            <strong>Settings</strong>
            </CDropdownHeader>
            <CDropdownItem>
            <CIcon name="cil-user" /> Profile
            </CDropdownItem>
            <CDropdownItem>
            <CIcon name="cil-settings" /> Settings
            </CDropdownItem>
            <CDropdownItem>
            <CIcon name="cil-dollar" /> Payments
            <CBadge color="secondary" class="mfs-auto">{{ itemsCount }}</CBadge>
            </CDropdownItem>
            <CDropdownItem>
            <CIcon name="cil-file" /> Projects
            <CBadge color="primary" class="mfs-auto">{{ itemsCount }}</CBadge>
            </CDropdownItem>
            <CDropdownDivider/>
            <CDropdownItem>
            <CIcon name="cil-shield-alt" /> Lock Account
            </CDropdownItem>-->
            
        </CDropdown>
        <!--<CRow>
            <CCol>
                <CSelect
                    size="sm"
                    :options="['Option 1', 'Option 2', 'Option 3']"
                    placeholder="Please select"
                />
                
            </CCol>
            <CCol>
                <CButton class="btnPrincipalVapp" style="min-height:15px" shape="pill" size="sm">Standard Button</CButton>
            </CCol>
        </CRow>-->
    </div>
</template>
<script src="https://gist.github.com/jmhdez/6405474/raw/8f4bdbb07b58eef2834ef72a66bdd56902e58419/console.js"></script>
<script>
    import Vue from 'vue'
    import cons from '@/const'
    import apiUrls from '@/views/utils/apiUrls'
    import axios from "axios"
    import { freeSet } from '@coreui/icons'
    
    import lunch from '@/assets/icons/lunch.svg'
    //import miIcono from './assets/icons/lunch.svg';
    export default {
        name: 'registrosAsistenciaTrabajador',
        freeSet,
        lunch,
        components: {    
        },
        data () {
            return {
                gloc: [],
                lat: null,
                long: null,
                err: null,
                urlBase: cons.port+"://" + cons.ipServer,
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                isLoadingEmpresas: false,
                mostrarEmpresas: false,
                empresasTrabajador: [],
                empresaSelected: null,
                datosRegistro: null
            }
        },
        created: function(){
            
        },
        beforeMount() {
            let cookieUsername = this.$cookie.get('userLogginToken')
            if(cookieUsername === null || cookieUsername === ""){
                this.$router.push("/pages/login");
            }
        },
        mounted(){
            this.obtenerGeo()
            this.getEmpresasTrabajador()
           
        },
        methods: {
            obtenerGeo: function(){
                 if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(position => {
                        this.lat = position.coords.latitude;
                        this.long = position.coords.longitude;
                    }, err => {this.err = err.message}, {
                        enableHighAccuracy: true, // Alta precisión
                        maximumAge: 0, // No queremos caché
                        timeout: 5000 // Esperar solo 5 segundos
                    });
                } else {
                    console.log("La geolocalización no está disponible en este navegador.");
                }
            },
            getEmpresasTrabajador: function(){
                this.isLoadingEmpresas = true;
                var url = this.urlBase + apiUrls.portalTrabajador.getEmpresasTrabajador
                axios({
                    url: url,
                    method: 'POST',
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    },

                }).then((response) => {
                    
                    //this.empresasTrabajador = response.data;
                    response.data.forEach(element => {
                         this.empresasTrabajador.push({value: element, label: element.nombreEmpresa})
                        
                    });
                    if(this.empresasTrabajador.length > 1){
                        this.mostrarEmpresas = true;
                    }
                    else{
                        this.seleccionarEmpresa(response.data[0])
                    }
                    
                    this.isLoadingEmpresas = false;

                })
                .finally(() => {
                    this.isLoadingEmpresas = false
                });
            },
            seleccionarEmpresa: function(empresa){
                this.empresaSelected = {value: empresa, label: empresa.nombreEmpresa};
                this.getRegistrosTrabajador()
            },
            registrarHorario: function(registro){
                var url = this.urlBase + apiUrls.portalTrabajador.registrarHorario
                axios({
                    url: url,
                    method: 'POST',
                    "data": {                        
                        "rutTrabajador": JSON.parse(this.$cookie.get("userLogginToken")).rutTrabajador,
                        "idTipoRegistro": registro.idTipoRegistro,
                        "latitudRegistro":  this.lat,
                        "longitudRegistro": this.long
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    },

                }).then((response) => {
                    registro.registrado = response.data.registrado
                    this.mostrarNotificaciones(1, response.data.mensajeGeneral)
                    this.getRegistrosTrabajador()
                }, error => {
                    this.mostrarNotificaciones(3, error.response.data.mensajeGeneral)
                    //this.$notification.error(error.response.data.mensajeGeneral, {  timer: 10, position:"bottomRight", showCloseIcn:true});
                })
                .finally(() => {
                });
            },
            
            getRegistrosTrabajador: function(){

                var url = this.urlBase + apiUrls.portalTrabajador.getRegistros
                
                axios({
                    url: url,
                    method: 'POST',
                    "data": {
                        "idEmpresa": this.empresaSelected.value.idEmpresa,
                        "rutTrabajador": JSON.parse(this.$cookie.get("userLogginToken")).rutTrabajador
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    },

                }).then((response) => {
                    this.datosRegistro = response.data.registros
                })
                .finally(() => {
                });

            },
        }
    }
    
</script>
