<template>
    <CHeader fixed with-subheader light :style="{background: $store.state.colorHeadeBar.hex8}">
        <CToggler
            in-header
            :style="{'color': $store.state.colorIconoBar}"
            class="ml-3 d-lg-none"
            @click="$store.commit('toggleSidebarMobile')">
            <!--<CIcon name="cil-list" height="28" />-->
        </CToggler>
        <CToggler
            in-header
            :style="{'color': $store.state.colorIconoBar}"
            class="ml-3 d-md-down-none"
            @click="$store.commit('toggleSidebarDesktop')">
            <!--<CIcon name="cil-list" height="28" />-->
        </CToggler>

        <!--<CHeaderBrand className="mx-auto d-lg-none" 
            @click="$store.commit('toggleSidebarDesktop')"">
            <CIcon name="cil-justify-center" />
        </CHeaderBrand>-->

        <CHeaderBrand class="mx-auto d-lg-none" to="/">
            <!--<CIcon name="logo" height="48" alt="Logo"/>-->
            <img src="/img/alt60px_logo-gesto.png" height="48" alt="Logo" />
        </CHeaderBrand>

        <!--<CHeaderNav class="d-md-down-none mr-auto">
            <CIcon name="cil-justify-center"/>
            <CToggler in-header @click="$store.commit('toggleSidebarDesktop')"/>
        </CHeaderNav>-->
        
        <CHeaderNav class="d-md-down-none mr-auto">
            <!--<CHeaderNavItem class="px-3">
                <CHeaderNavLink to="/dashboard">
                Dashboard
                </CHeaderNavLink>
            </CHeaderNavItem>
            <CHeaderNavItem class="px-3">
                <CHeaderNavLink to="/users" exact>
                Users
                </CHeaderNavLink>
            </CHeaderNavItem>
            <CHeaderNavItem class="px-3">
                <CHeaderNavLink>
                Settings
                </CHeaderNavLink>
            </CHeaderNavItem>-->
            <!--<CDropdown
                inNav
                class="c-header-nav-items"
                placement="bottom-end"
                add-menu-classes="pt-0"
            >
                <template #toggler>
                <CHeaderNavLink>
                    <div class="c-avatar">
                    <CIcon name="cil-envelope-open"/>
                    <span
                        class="c-avatar-status"
                        ></span>
                    </div>
                </CHeaderNavLink>
                </template>
                <CDropdownHeader tag="div" class="text-center" color="light">
                Color Pick
                </CDropdownHeader>
                <CDropdownItem >
                
                <chrome-picker v-model="colors" @input="cambiarColor" />
                </CDropdownItem>
            </CDropdown>-->
        
        </CHeaderNav>
        
        
        <CHeaderNav class="mr-4">
            <CHeaderNavItem class="mx-2" v-if="esTest">
                <tippy 
                    interactive
                    :animate-fill="false"
                    placement="bottom"
                    distant="7"
                    theme="light"
                    animation="fade"
                    trigger="click"
                    arrow>
                    <template v-slot:trigger>
                        <CHeaderNavLink>
                            <div class="c-avatar">
                                <CIcon :style="{'color': $store.state.colorIconoBar}" style="height: 25px; width: auto;" :content="$options.freeSet.cilColorPalette" />
                            </div>
                        </CHeaderNavLink>
                    </template>
                    <CRow>
                        <CCol col="6">
                            <CDropdownHeader tag="div" class="text-center" color="light">
                                <strong>Color Cabecera</strong>
                            </CDropdownHeader>
                            <div><chrome-picker style="width: auto" v-model="colors" @input="$store.commit('changeColorHeadeBar', colors)" /></div>
                        </CCol>
                        <CCol col="6">
                            
                            <CDropdownHeader tag="div" class="text-center" color="light">
                                <strong>Color Barra lateral</strong>
                            </CDropdownHeader>
                            <div><chrome-picker style="width: auto" v-model="colors2" @input="$store.commit('changeColorSideBar', colors2)" /></div>
                        </CCol>
                        <!--<CCol col="6">
                            
                            <CDropdownHeader tag="div" class="text-center" color="light">
                                <strong>Color Fondo</strong>
                            </CDropdownHeader>
                            <div><chrome-picker style="width: auto" v-model="colors2" @input="$store.commit('changeColor', colors2)" /></div>
                        </CCol>-->
                    </CRow>
                        
                </tippy>
            </CHeaderNavItem>
            
            <!--<CDropdown
                inNav
                class="c-header-nav-items"
                placement="bottom-end"
                add-menu-classes="pt-0"
            >
                <template #toggler>
                <CHeaderNavLink>
                    <div class="c-avatar">
                    <CIcon :style="{'color':icoColor}"  name="cil-envelope-open"/>
                    <span
                        class="c-avatar-status"
                        ></span>
                    </div>
                </CHeaderNavLink>
                </template>
            </CDropdown>-->
            <!--
            <CHeaderNavItem class="d-md-down-none mx-2">
                <CHeaderNavLink>
                <CIcon name="cil-bell"/>
                </CHeaderNavLink>
            </CHeaderNavItem>
            <CHeaderNavItem class="d-md-down-none mx-2">
                <CHeaderNavLink>
                <CIcon name="cil-list"/>
                </CHeaderNavLink>
            </CHeaderNavItem>
            <CHeaderNavItem class="d-md-down-none mx-2">
                <CHeaderNavLink>
                <CIcon name="cil-envelope-open"/>
                </CHeaderNavLink>
            </CHeaderNavItem>-->

            <!--<CButton
                variant="ghost"
                size="sm"
                color="success"
                @click="actualizar"
            >
                <CIcon size="sm" :content="$options.freeSet.cilLoopCircular" :class="classActualizar" /> Actualizar
            </CButton>-->
        

            <!--<Notificaciones />-->
            
            <RegistrosAsistenciaTrabajador v-if="usauarioTrabajador" />
            <TheHeaderDropdownAccnt />
        </CHeaderNav>

        <CSubheader class="px-3" v-if="isMobile">
            <CBreadcrumbRouter class="border-0 mb-0"/>
        </CSubheader>

        
    </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import Notificaciones from './Notificaciones'
import RegistrosAsistenciaTrabajador from './registrosAsistenciaTrabajador'

import * as util from '@/views/utils/utilsFunctions'

import { freeSet } from '@coreui/icons'

  import { Material, Compact, Swatches, Slider, Sketch, Chrome, Photoshop } from 'vue-color'
  //import LvColorpicker from 'lightvue/color-picker';

export default {
  name: 'TheHeader',
  freeSet,
  components: {
    TheHeaderDropdownAccnt,
    Notificaciones,
    RegistrosAsistenciaTrabajador,
    'chrome-picker': Chrome,
    //LvColorpicker,
  },
  data () {
    return {
      classActualizar: "",
      usauarioTrabajador: JSON.parse(this.$cookie.get('userLogginToken')).trabajador,
      isMobile: false,
      navbarText: false,
      colors: {
            hex: '#fff',
            hex8: '#fff',
            hsl: { h: 150, s: 0.5, l: 0.2, a: 1 },
            hsv: { h: 150, s: 0.66, v: 0.30, a: 1 },
            rgba: { r: 25, g: 77, b: 51, a: 1 },
            a: 1
        },
        colors2: {
            hex: '#fff',
            hex8: '#fff',
            hsl: { h: 150, s: 0.5, l: 0.2, a: 1 },
            hsv: { h: 150, s: 0.66, v: 0.30, a: 1 },
            rgba: { r: 25, g: 77, b: 51, a: 1 },
            a: 1
        },

        hexCode: '#607C8A',

        esTest: false,
        icoColor: "#29235C"
    }
  },  
    beforeMount(){
        if(this.$route.query.hasOwnProperty('test')){
            this.esTest = this.$route.query.test
        }

    },
  mounted(){
    this.checkDevice()    
  },
  methods:{
    actualizar: function(){
      this.classActualizar = "imgr"
    },
    checkDevice() {
        this.isMobile = util.getTipoDispositivoEsMobile()
    },
    cambiarColor: function(){
        this.icoColor = this.obtenerColorIcono()
    },
    
    obtenerColorIcono: function() {
        let luminancia = util.calcularLuminancia(this.colors.hex8);
        //console.log("luminancia", luminancia)
        // Umbral de contraste (0.5 suele ser un buen valor)
        return luminancia > 0.2 ? '#29235C' : 'white';
    }
  }
}
</script>

<style >
@keyframes rotate {from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}}
@-webkit-keyframes rotate {from {-webkit-transform: rotate(0deg);}
  to {-webkit-transform: rotate(360deg);}}
.imgr{
    -webkit-animation: 3s rotate linear infinite;
    animation: 3s rotate  linear infinite;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}
.tippy-content{
max-width: 500px;}
</style>